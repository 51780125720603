<script setup lang="ts">
import moment from 'moment'
import NavBar from '~/layouts/components/NavBar.vue'

const footer = [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
    { name: 'Support', href: '/support' },
]
</script>

<template>
    <div class="bg-white">
        <NavBar />

        <div class="bg-white">
            <main>
                <slot />
            </main>
        </div>

        <footer class="bg-white">
            <div class="mx-auto mb-6 mt-32 max-w-7xl overflow-hidden border-t border-gray-100 p-6 lg:px-8">
                <nav
                    class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
                    aria-label="Footer"
                >
                    <div
                        v-for="item in footer"
                        :key="item.name"
                        class="pb-6"
                    >
                        <NuxtLink
                            :to="item.href"
                            class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </div>
                </nav>

                <p class="mt-10 text-center text-xs leading-5 text-gray-500">
                    &copy; {{ moment().year() }} Civiqa
                    Inc. All rights
                    reserved.
                </p>
            </div>
        </footer>
    </div>

    <!-- <div v-if="billieContext" class="mx-auto sticky max-w-5xl bottom-0 group" @click="billieTapped()">
        <div class="absolute right-3 bottom-5 cursor-pointer">
            <div class="animate-bounce-short chat chat-end">
                <div class="chat-image avatar">
                    <div class="w-[75px] bg-green-100 rounded-full border shadow-lg border-green-300">
                        <img class="p-1" src="/cartoon.png" />
                    </div>
                </div>
                <div
                    class="opacity-0 group-hover:opacity-100 duration-300 transition-all chat-bubble bg-gray-600 text-white text-sm shadow-md">
                    <div v-if="civiqaUser && !civiqaUser.isFirebaseAnonymous">
                        Have a question for me?
                    </div>
                    <div v-else>I use AI to help registered users with questions they have. Tap here to sign up.</div>
                </div>
            </div>
        </div>
    </div> -->
</template>
